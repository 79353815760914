import React from 'react'
import './footer.css'

export default function Footer() {
    return (
        <div className="main">
            <div className="mainBox">
                <div className="mainCont2">
                    <p>CHATTANOOGA PRIMITIVE BAPTIST CHURCH 811 RUNYAN DRIVE, CHATTANOOGA, TN, 37405 UNITED STATES</p>
                    <p>(423) 756-1953</p>
                </div>
            </div>
        </div>
    )
}
